.optionsContainer {
	display: flex;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
	margin-right: 10px;

	& > div > div {
		display: flex;
	}
}
.list {
	.dropdownIem {
		display: flex;
		justify-content: left;
		align-items: center;

		&:first-child {
			border-bottom: 1px solid #ececec;
		}

		.icon {
			height: 27px;
			width: 27px;
		}
	}
}

.dropdownIemDisabled {
	cursor: not-allowed;
	opacity: 0.4;
}

.displayNameCol {
	align-items: center;
	display: flex;
	& > span {
		margin-right: 10px;
	}
}

.iconCol {
	width: 100%;
	display: flex;
	justify-content: center;
}

.upgradeAllToProText {
	margin-left: 5px;
}
