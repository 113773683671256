.container {
	background: #515151;
	color: #fff;
	border-top: 1px solid #515151;
	border-radius: 0px 0px 7px 7px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px;

	.rightContainer {
		display: flex;
		align-items: center;

		.pageSelection {
			display: flex;
			align-items: center;
			margin-right: 10px;

			.pageItem {
				margin: 0 3px;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 6px;
				min-height: 33px;
				min-width: 33px;
				border-radius: 9999px;

				&.dots {
					&:hover {
						cursor: auto;
						background: transparent;
					}
				}

				&.currentPage {
					background: #0063f7;
					&:hover {
						cursor: auto;
						background: #0063f7;
					}
				}

				&:hover {
					cursor: pointer;
					background: #6da5fa;
				}
			}
		}

		.rowsPerPage {
			display: flex;
			justify-content: center;
			align-items: center;

			.dropDownContent {
				margin-left: 6px;
				display: flex;
				cursor: pointer;
				align-items: center;

				& :hover {
					opacity: 0.75;
				}

				& svg {
					fill: #fff;
					height: 16px;
					width: 16px;
				}
			}
		}
	}

	.leftContainer {
		display: flex;
		align-items: center;

		b {
			margin: 0 3px;
		}

		.resultCountContainer {
			display: flex;
			margin-left: 46px;
			align-items: center;
			margin-right: 10px;

			.paginationArrow {
				height: 24px;
				width: 24px;
				cursor: pointer;

				& :hover {
					opacity: 0.75;
				}
			}

			.disabledPaginationArrow {
				fill: grey;
				cursor: not-allowed;
			}

			.arrowLeft {
				margin: 0 10px;
			}
		}
	}
}
