.openedClassName {
	max-height: 240px;
	overflow: hidden;
	overflow-y: scroll;
}

.genericSelectList {
	position: relative;
	width: 100%;
	background-color: #fff;
	border-radius: 4px;
	color: #51606d;
	border: 1px solid #51606d;
	padding: 7px 10px;
}
